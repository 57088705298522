import { texto } from './../svg/svgEstandar/modelo/svg';
import { Icon } from "../iconos/iconos"
import { EModeloIcono } from "../components/icono-svg/models/enums"
import { EColorBoton } from "./entry-point"

export enum ETipoModal {
    exito = '--textoLista-boton-exito-400',
    informacion = '--textoLista-boton-info',
    alerta = '--backgroundLista-alerta-advertencia-icono',
    error = '--backgroundLista-boton-peligro-enfocado'
}

export const transformTipoColorFuente = {
    [ETipoModal.exito]: 'g-color-boton-exito-enfocado',
    [ETipoModal.informacion]: 'g-color-boton-info',
    [ETipoModal.alerta]: 'g-color-alerta-advertencia',
    [ETipoModal.error]: 'g-color-boton-error-enfocado'
}

export const transformTipoModeloIcono = {
    [ETipoModal.exito]: EModeloIcono.exito,
    [ETipoModal.informacion]: EModeloIcono.info,
    [ETipoModal.alerta]: EModeloIcono.advertencia,
    [ETipoModal.error]: EModeloIcono.error
}

export const transformTipoIcono = {
    [ETipoModal.exito]: Icon.Nuevo,
    [ETipoModal.informacion]: Icon.InfoNegativo,
    [ETipoModal.alerta]: Icon.Exclamacion,
    [ETipoModal.error]: Icon.CerrarModal
}

export interface ItipoModalReserva {
  tipo: ETipoModal;
  icono: string;
  titulo: string;
  boton1?: IbotonModal;
  boton2?: IbotonModal;
  boton3?: IbotonModal;
}

export interface IbotonModal{
  color: EColorBoton;
  icono?: string;
  texto: string;
  clase: string;
  click: () => any;
}

export enum ETipoModalReserva {
  reservaExitosaPedirAsiento,
  reservaExitosa,
  confirmar,
  procesando,
  rechazo,
  rechazoAsiento,
  error,
  momento
}
