import { IOpcionesDatosReserva } from "src/app/core/models/autoreserva/modelosCompartidos";

export const opcionesViajeros: IOpcionesDatosReserva = {
    "Proyectos": {
        "objetos": [
            {
                "id": 3721,
                "Codigo": "Proyecto 15",
                "Descripcion": "Descripción 15"
            },
            {
                "id": 3720,
                "Codigo": "Identificador 00",
                "Descripcion": "Descripción 00"
            },
            {
                "id": 2710,
                "Codigo": "Proyecto 5",
                "Descripcion": "Proyecto de TEST 5"
            },
            {
                "id": 2709,
                "Codigo": "Proyecto 4",
                "Descripcion": "Proyecto de TEST 4"
            },
            {
                "id": 2708,
                "Codigo": "Proyecto 3",
                "Descripcion": "Proyecto de TEST 3"
            },
            {
                "id": 2707,
                "Codigo": "Proyecto 2",
                "Descripcion": "Proyecto de TEST 2"
            },
            {
                "id": 2706,
                "Codigo": "Proyecto 1",
                "Descripcion": "Proyecto de TEST 1"
            },
            {
                "id": 1664,
                "Codigo": "0003",
                "Descripcion": "Proyecto de prueba 3"
            },
            {
                "id": 1663,
                "Codigo": "0002",
                "Descripcion": "Proyecto de prueba 2"
            },
            {
                "id": 1662,
                "Codigo": "0001",
                "Descripcion": "Proyecto de prueba 1"
            },
            {
                "id": 1661,
                "Codigo": "0000",
                "Descripcion": "Sin Proyecto"
            }
        ],
        "denominacion": "Proyecto"
    },
    "Referencia": {
        "TipoSolicitud": "Opcional",
        "Restriccion": null
    },
    "EjesEstrategicos": [
        {
            "Id": 96,
            "Nombre": "Ocio"
        },
        {
            "Id": 97,
            "Nombre": "Convenciones"
        }
    ]
}
