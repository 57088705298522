import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { grosorIconoAttribute, modeloIconoAttribute } from '@destinux/destinux-transforms';
import { EGrosorIcono, EModeloIcono, ETallaIcono } from '../icono-svg/models/enums';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { SvgService } from '../../../../../apps/destinux/src/app/core/services/svg/svg.service';
import { environment } from '@destinux/environment';

@Component({
  selector: 'destinux-icono-svg',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icono-svg.component.html',
  styleUrls: ['./icono-svg.component.scss'],
})
export class IconoSvgComponent implements OnInit {
  
   
  @ViewChild('icono',{static: true}) icono!: ElementRef<object>;

  @Input({required: true}) urlIcono: string = '';
  @Input() modelo: EModeloIcono = EModeloIcono.primario;
  @Input({required: true}) talla!: ETallaIcono; // de XG a XP y con "fill": 100%
  @Input() excepcionGrosor!: EGrosorIcono; // de XG a XP y con "fill": 100%
  @Input() class: string = "";

  readonly valoresPorDefecto = {
    clasesBase: "contenedor-icono",
    modelo: EModeloIcono.primario
  };

  svg_safe: SafeHtml | undefined;
  clases: string[] = [];
  listaClases: string = '';

  constructor(
    private _http: HttpClient,
    private _sanitizer: DomSanitizer,
    private _svgService: SvgService
    ) {
    this.inicializarPropiedades();
  }

  ngOnInit() {
    //this.aplicarClases();
    this.obtenerSVG();
  }
  
  //#region html
  getGrosor(){
    return this.excepcionGrosor ? ` grosor-${this.excepcionGrosor}` : "";
  }
  //#endregion

  aplicarClases():void {
    this.clases.push(this.valoresPorDefecto.clasesBase);
    if(this.talla) this.clases.push(`talla-${this.talla}`);
    if(this.excepcionGrosor) this.clases.push(`grosor-${this.excepcionGrosor}`);
    
    switch (this.modelo) {
      case EModeloIcono.primario: this.clases.push('primario');
        break;
      case EModeloIcono.secundario: this.clases.push('secundario');
        break;
      case EModeloIcono.negativo: this.clases.push('negativo');
        break;
      case EModeloIcono.desactivado: this.clases.push('desactivado');
        break;
      case EModeloIcono.advertencia: this.clases.push('advertencia');
        break;
      case EModeloIcono.alerta: this.clases.push('alerta');
        break;
      case EModeloIcono.error: this.clases.push('error');
        break;
      case EModeloIcono.info: this.clases.push('info');
        break;
      case EModeloIcono.exito: this.clases.push('exito');
        break;
      case EModeloIcono.destaque: this.clases.push('destaque');
        break;
      case EModeloIcono.login: this.clases.push('login');
        break;
    
      default: this.clases.push('primario');
        break;
    }
    
    this.listaClases = this.clases.join(' ');
  }

  inicializarPropiedades():void{
    this.modelo = this.valoresPorDefecto.modelo;
  }

  async obtenerSVG(): Promise<void> {
    await this._svgService.obtenerSVG(this.urlIcono).then(respuesta => {
      this.svg_safe = this._sanitizer.bypassSecurityTrustHtml(respuesta);
    });
  } 
}
