export enum EModeloIcono{
  primario = 'primario',
  secundario = 'secundario',
  desactivado = 'desactivado',
  negativo = 'negativo',
  heredado = 'heredado',
  advertencia = 'advertencia', // Para icono-svg // coloresTexto
  alerta = 'alerta', // Para icono-svg // coloresTexto
  error = 'error', // Para icono-svg
  info = 'info', // Para icono-svg
  exito = 'exito', // Para icono-svg
  destaque = 'destaque', // Para icono-svg
  login = "login" // Para icono-svg
}

export enum EGrosorIcono{
  "uno" = 1,
  "dos" = 2,
  "tres" = 3,
  "cuatro" = 4,
  "cinco"= 5
}

export enum ETallaIcono{
  fill = "fill",
  xg = "xg",
  gd = "gd",
  md = "md",
  pq = "pq",
  xp = "xp"
}
