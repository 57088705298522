/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceHttpService } from '../../service-http.service';
import {
  IDatosReservaRentacar,
  IRespuestaDiponibilidadRentACar,
  IRespuestaVenta,
  PeticionDisponibilidadRentacar,
} from 'src/app/core/models/autoreserva/rentACar/rentacar';
import { environment } from '@env/environment';
import { AutoreservaService } from '../autoreserva.service';
import { EnvironmentEnum } from '@env/environments.enum';
import { peticionRenaCar } from '@assets/misc/respuestas_API/autoreserva/rentacar/peticionRentacar';
import { repuestaDisponibilidadRentacar } from '@assets/misc/respuestas_API/autoreserva/rentacar/repuestaDisponibilidad';
import { IOpcionesDatosReserva } from 'src/app/core/models/autoreserva/modelosCompartidos';

@Injectable({
  providedIn: 'root',
})
export class RentacarService extends AutoreservaService {
  //#region declaración de variables
  infoUser$ = new BehaviorSubject<any>(null);
  ramasTraducidas$ = new BehaviorSubject<any>(null);

  #proyectos = new BehaviorSubject<any>(null);
  proyectos$ = this.#proyectos.asObservable();

  #listaCochesRaw = new BehaviorSubject<any>([]);
  listaCochesRAW$ = this.#listaCochesRaw.asObservable();

  #cochesFiltrados = new BehaviorSubject<any>([]);
  cochesFiltrados$ = this.#cochesFiltrados.asObservable();

  #coordenadas = new BehaviorSubject<{ latitud: any; longitud: any } | null>(
    null
  );
  coordenadas$ = this.#coordenadas.asObservable();

  #primerCocheNoCumple = new BehaviorSubject<any>(null);
  primerCocheNoCumple$ = this.#primerCocheNoCumple.asObservable();

  #peticion_rentacar = new BehaviorSubject<any>(null);
  _peticion_rentacar$ = this.#peticion_rentacar.asObservable();

  #info_user = new BehaviorSubject<any>(null);
  info_user$ = this.#info_user.asObservable();

  datos_prereserva = new BehaviorSubject<IDatosReservaRentacar | null>(null);
  datos_prereserva$ = this.datos_prereserva.asObservable();

  esAeropuerto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  respuestaAeropuertoOrigen: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  #respuestaDisponibilidad =
    new BehaviorSubject<IRespuestaDiponibilidadRentACar | null>(null);
  respuestaDisponibilidad$ = this.#respuestaDisponibilidad.asObservable();

  #reglas = new BehaviorSubject<any[]>([]);
  reglas$ = this.#reglas.asObservable();



  //#endregion

  //#region constructor
  constructor(protected override _http: ServiceHttpService) {
    super(_http);
  }
  //#endregion

  //#region metodos getter and setter
  setDatosPrereserva(datos: IDatosReservaRentacar) {
    this.datos_prereserva.next(datos);
  }

  guardarProyectos(proyectos: any): void {
    this.#proyectos.next(proyectos);
  }

  cambiarCochesRAW(coches: any): void {
    this.#listaCochesRaw.next(coches);
  }

  cambiarCochesFiltrados(coches: any): void {
    this.#cochesFiltrados.next(coches);
  }

  setCoordenadas(coordenadas: { latitud: any; longitud: any }): void {
    this.#coordenadas.next(coordenadas);
  }

  cambiarPrimerCocheNoCumple(primerCocheNoCumple: any): void {
    this.#primerCocheNoCumple.next(primerCocheNoCumple);
  }

  enviarPeticionRentaCar(peticionRentaCar: any): void {
    this.#peticion_rentacar.next(peticionRentaCar);
  }

  getPeticionDisponibilidad(): PeticionDisponibilidadRentacar {
    if (environment.entorno == EnvironmentEnum.DEMO) {
      return peticionRenaCar;
    }
    return this.#peticion_rentacar.getValue();
  }

  enviarInfoUser(infoUser: any): void {
    this.#info_user.next(infoUser);
  }

  setRespuestaDisponibilidad(respuesta: IRespuestaDiponibilidadRentACar): void {
    this.#respuestaDisponibilidad.next(respuesta);
  }

  getRespuestaDisponibilidad(): IRespuestaDiponibilidadRentACar | null {
    if (
      environment.entorno == EnvironmentEnum.DEMO ||
      environment.entorno == EnvironmentEnum.LOCAL
    ) {
      return repuestaDisponibilidadRentacar;
    } else {
      return this.#respuestaDisponibilidad.getValue();
    }
  }
  setReglas(regla: any): void {
    this.#reglas.next(regla);
  }

  getReglas(): any {
    return this.#reglas.getValue();
  }
  //#endregion

  //#region metodos httpService
  GetDisponibilidad(
    peticion: PeticionDisponibilidadRentacar
  ): Observable<IRespuestaDiponibilidadRentACar> {
    let tipo: number;
    peticion.Recogida === peticion.Devolucion ? (tipo = 0) : (tipo = 2);
    const peticion_ = {
      FechaRecogida: peticion.FechaRecogida + ' ' + peticion.HoraRecogida,
      FechaDevolucion: peticion.FechaDevolucion + ' ' + peticion.HoraDevolucion,
      Recogida: {
        Latitud: peticion.Recogida?.Latitud,
        Longitud: peticion.Recogida?.Longitud,
        Direccion: peticion.Recogida?.Nombre,
      },
      Devolucion: {
        Latitud: peticion.Devolucion?.Latitud,
        Longitud: peticion.Devolucion?.Longitud,
        Direccion: peticion.Devolucion?.Nombre,
      },
      CodigoCompany: peticion.CodigoCompany || null,
      NumeroVuelo: peticion.NumeroVuelo || null,
    };

    return this._http.postApi<IRespuestaDiponibilidadRentACar>(
      'DisponibilidadRentacar2',
      peticion_,
      `${
        environment.baseJsonPruebas
      }autoreserva/rentacar/busqueda_${this.getCadenaTipo(tipo)}_dev.json`
    );
  }

  PostSeleccionarViajeros(peticion: any): Observable<any> {
    return this._http.postApi(
      'SeleccionarViajeros',
      peticion,
      environment.baseJsonPruebas + 'autoreserva/seleccionar_viajeros.json'
    );
  }

  PostIniciarReserva(
    opciones_seleccionadas: any[]
  ): Observable<IRespuestaVenta> {
    return this._http.postApi<IRespuestaVenta>(
      'IniciarReserva2',
      opciones_seleccionadas,
      environment.baseJsonPruebas + 'autoreserva/rentacar/iniciar_reserva.json'
    );
  }

  getMasInfoTarifa(id_coche: string, tipo = 0): Observable<any> {
    return this._http.postApi('MasInfoTarifa2', { id: id_coche},
      `assets/misc/respuestas_API/autoreserva/rentacar/prereserva_${this.getCadenaTipo(tipo)}_dev.json`);
  }
  //#endregion

  //#region metodos
  getCadenaTipo(tipo: number): string {
    let cadena;
    switch (tipo) {
      case 0:
        cadena = 'misma';
        break;

      case 2:
        cadena = 'dist';
        break;

      default:
        cadena = '';
        break;
    }
    return cadena;
  }

  deleteStateRentACar(): void {
    setTimeout(() => {
      this.#peticion_rentacar.next(null);
      this.#cochesFiltrados.next([]);
      this.#listaCochesRaw.next([]);
    }, 500);
  }

  unsubscribe(): void {
    this.#peticion_rentacar.unsubscribe();
  }
  //#endregion
}
